import React from 'react';

class News extends React.Component {

   
    render() {
        const { location } = this.props;
        const { title } = this.props.location.state;
        const { name } = this.props.match.params;
        
        return title ? (
          <div>
            <h1>{title}</h1>
            <p>{/* Aggiungi qui la descrizione della notizia */}</p>
            <p>{/* Aggiungi qui il contenuto della notizia */}</p>
          </div>
        ) : (
          <div>
            <p>Error: no news found.{title}</p>
          </div>
        );
      }

}

export default News;
