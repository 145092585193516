import React from 'react';
import axios from 'axios';
import { Route, Link, BrowserRouter as Router } from 'react-router-dom';
import News from './news';

class Headlines extends React.Component{

    constructor(props){
        super(props)
        this.state = {
            headlinesNews: [],
            isLoading: true,
            errors: null
        };
    }

    getUsers(catags) {
        // API di Axios
        axios.get('https://newsapi.org/v2/top-headlines',{
            params: {country: catags, apiKey: 'b21c99104f5a40cb835e0a590d0e2e0f'}
        })
          // Aspettiamo la response dall'API
          .then(response =>
            response.data.articles.map(news => ({
              title: `${news.title}`,
              description: `${news.description}`,
              author: `${news.author}`,
              newsurl: `${news.url}`,
              url: `${news.urlToImage}`,
              content: `${news.content}`
            }))
          )
          // Let's make sure to change the loading state to display the data
          // Cambio lo stato
          .then(headlinesNews => {
            this.setState({
              headlinesNews,
              isLoading: false
            });
          })
          // We can still use the `.catch()` method since axios is promise-based
          .catch(error => this.setState({ error, isLoading: false }));
    }

    componentDidMount() {
        this.getUsers('it')
    }

    handleClick = (title) => {
        this.props.history.push({
          pathname: '/news',
          state: { title: title }
        });
      }

    render(){
        const { isLoading, headlinesNews } = this.state;
        return (
            <React.Fragment>
            <div className="subhead"><h2>Headlines</h2></div>
            <div>
                {!isLoading ? (
                headlinesNews.map(news => {
                    const { title,  author,description, newsurl, url , content} = news;
                    
                    return (
                    <div className="collumn" key={title}>
                        <div className="head">
                            <span className="headline hl3">
                                {title}
                            </span>
                            <p>
                                <span className="headline hl4">
                                    {author}
                                </span>
                            </p>
                            <figure className="figure">
								<img className="media" src={url} alt="" />
						    </figure>
                            {description && description !== "null" && (<p><strong>{description}</strong></p>)}<br />
                            {content && content !== "null" && <p>{content}</p>}
                            <a href={newsurl} target="_blank" rel="noopener noreferrer">Read full news</a>
                            <Router>
                            <Link to="/news/asdasd">asdasd</Link>
                            <Route path="/news/:name" component={News} />
                            </Router>
                        </div>
                    </div>
                    );
                })
                ) : (
                <p>Loading...</p>
                )}
            </div>
            </React.Fragment>
        );
    }
}

export default Headlines;