import React, { Component } from 'react';
import {Icon} from 'semantic-ui-react';
import './Footer.css';

export class Footer extends Component {
    render() {
        return (
            <div data-aos="fade-up" className="footer_main">
                <div className="footer_Icons">
                 
                </div>
                <div  className="footer_content">
                    <span>Copyrights <Icon name="copyright" />2023 - Designed and Developed by me (aka) mamflo</span>
                </div>
            </div>
        )
    }
}

export default Footer;
